import React from "react";

import { Link } from "react-router-dom";

export default function Contact() {
  return (
    <>
      {/* Contact */}
      <div className="contact section-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-5">
              {/* Contact Info */}
              <h4 className="mb-4">
                Office <span>Address</span>
              </h4>
              <p>
                <i className="ti-map" /> <b>Shiv Design</b><br/>
                3D Architecture & Interior
                <br />
                <b>Deep Andharia</b>
                <br />
                B-8, Sai-shila Complex,
                <br />
                Nr D.S.P Office, <br />
                Bhavnagar - 364001
                <br />
                Gujarat - (India)
              </p>

              <div className="social mt-2">
                <a href="index.html">
                  <i className="ti-twitter" />
                </a>
                <a href="index.html">
                  <i className="ti-instagram" />
                </a>
                <a href="index.html">
                  <i className="ti-linkedin" />
                </a>
              </div>
            </div>
            <div className="col-md-4">
              {/* Contact Info */}
              <h4 className="mb-4">
                Email <span>Address</span>
              </h4>

              <div className="mail mb-3">
                <a className="clw" href="mailto:dpandharia@gmail.com">
                  {" "}
                  <i className="ti-envelope" /> dpandharia@gmail.com
                </a>
              </div>
            </div>
            <div className="col-md-3">
              {/* Contact Info */}
              <h4 className="mb-4">
                Mobile <span>Number</span>
              </h4>

              <div className="phone">
                <a className="clw" href="callto:9712654564">
                  <i className="ti-mobile" /> +91 9712654564
                </a>
                <br />
                <a className="clw" href="callto:8320452771">
                  <i className="ti-mobile" /> +91 8320452771
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
