import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function PortfolioDetails() {
  const [portfoliolistdata, setportfoliolistdata] = useState({});
  const location = useLocation();

  const carouselSettings = {
    dots: false, // Show dots navigation
    infinite: true, // Infinite loop
    speed: 1000, // Transition speed in milliseconds
    slidesToShow: 1, // Number of slides to show at a time
    slidesToScroll: 1, // Number of slides to scroll per click
    autoplay: true, // Enable autoplay
    autoplaySpeed: 4000, // Autoplay interval in milliseconds (3 seconds in this
  };

  useEffect(() => {
    function ViewPortfolio() {
      axios
        .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
          method: "portfolio",
          submethod: "getbyid",
          id: location.state?.id,
          key: process.env.REACT_APP_KEY,
        })
        .then((res) => {
          console.log("View Portfolio Data", res.data.data);
          setportfoliolistdata(res.data.data);
        });
    }

    console.log("location.state.id", location.state?.id);
    ViewPortfolio();
  }, [location.state?.id, portfoliolistdata]);

  return (
    <>
      <section
        className="project-page section-padding"
        onContextMenu={(e) => e.preventDefault()}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-12">
              {portfoliolistdata.image && portfoliolistdata.image2 ? (
                <Slider {...carouselSettings}>
                  <div className="portfolio-item">
                    <img
                      className="img-fluid"
                      src={`${process.env.REACT_APP_BACKEND_URL}uploads/${portfoliolistdata.image}`}
                      alt={portfoliolistdata.portfolio_name}
                    />
                  </div>
                  <div className="portfolio-item">
                    <img
                      className="img-fluid"
                      src={`${process.env.REACT_APP_BACKEND_URL}uploads/${portfoliolistdata.image2}`}
                      alt={portfoliolistdata.portfolio_name}
                    />
                  </div>
                </Slider>
              ) : (
                <p>Loading...</p>
              )}

              <div className="row">
                <div className="col-md-8">
                  <div className="project-bar">
                    <div className="row justify-content-between align-items-center text-left text-lg-start">
                      <div className="col-md-6 mb-15">
                        <h5>Project</h5>
                        <h6>{portfoliolistdata.portfolio_name}</h6>
                      </div>
                      <div className="col-md-6 mb-15">
                        <h5>Date</h5>
                        <h6>{portfoliolistdata.date}</h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p>{portfoliolistdata.description}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
