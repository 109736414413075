import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";

import { Link } from "react-router-dom";
import PortfolioDescriptionBanner from "./PortfolioDescriptionBanner";
import PortfolioDetails from "./PortfolioDetails";

export default function PortfolioDescription() {
  useEffect(() => {
    document.title =
      "Portfolio Description | Shiv Design 3D Architecture & Interior";
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <Header />

        <PortfolioDescriptionBanner />

        <PortfolioDetails />

        <Footer />
      </div>
    </>
  );
}
