import React, { useEffect } from "react";
import axios from "axios";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";

import { Link } from "react-router-dom";
import Banner from "./Banner";
import PortfolioList from "./PortfolioList";

export default function Portfolio() {
  useEffect(() => {
    document.title =
      "Portfolio | Shiv Design 3D Architecture & Interior";
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <Header />

        <Banner />

        <PortfolioList />

        <Footer />
      </div>
    </>
  );
}
