import React, {useEffect} from "react";
import axios from "axios";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";

import { Link } from "react-router-dom";
import Slider from "./Slider";
import About from "./About";
import Subscribe from "./Subscribe";
import Portfolio from "./Portfolio";

export default function Home() {
  
  useEffect(() => {
    document.title = 'Shiv Design 3D Architecture & Interior';
  }, []);

  return (
    <>
      <div className="content-wrapper">
      
        <Header />

        <Slider />

        <About />

        <Portfolio />

        <Subscribe />

        <Footer />

      </div>
    </>
  );
}
