import React, { useEffect } from 'react';
import axios from "axios";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";

import { Link } from "react-router-dom";
import Contact from "./Contact";
import GoogleMap from "./GoogleMap";
import Banner from "./Banner";

export default function ContactUs() {

  useEffect(() => {
    document.title = 'Contact Us | Shiv Design 3D Architecture & Interior';
  }, []);

  return (
    <>
      <div className="content-wrapper">
      
        <Header />

        <Banner/>

        <Contact />

        <GoogleMap />

        <Footer />

      </div>
    </>
  );
}
