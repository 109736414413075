import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

export default function ImageGallery() {

  const [imagegallerylistdata, setimagegallerylistdata] = useState([]);

  function ViewImageGallery() {
    axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
        method: "gallery",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        console.log("View Image Gallery Data", res.data.data);
        setimagegallerylistdata(res.data.data);
      });
  }

  useEffect(() => {
    ViewImageGallery();
  }, []);

  return (
    <>
      <section className="section-padding" onContextMenu={(e) => e.preventDefault()}>
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-4">
              <div className="sub-title border-bot-light">Images</div>
            </div>
            <div className="col-md-8">
              <div className="section-title">
                <span>Image</span> Gallery
              </div>
              
            </div>
          </div>
          <div className="row">

            {imagegallerylistdata?.map((imagegallery) => (
              <div className="col-md-4 gallery-item" onContextMenu={(e) => e.preventDefault()}>
                <Zoom>
                  <img
                    src={`${process.env.REACT_APP_BACKEND_URL}` + "uploads/" + `${imagegallery.image}`}
                    className="img-fluid mx-auto d-block"
                    alt={imagegallery.image}
                    onContextMenu={(e) => e.preventDefault()}
                  />
                </Zoom>
              </div>
            ))}
           

          </div>
        </div>
      </section>
    </>
  );
}
