import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import { Link } from "react-router-dom";

export default function Slider() {
  return (
    <>
      {/* Slider */}
      <header id="slider-area" className="header slider-fade" onContextMenu={(e) => e.preventDefault()}>
        <OwlCarousel
          items={1}
          loop
          autoplay={true}
          autoplayTimeout={5000}
          nav={true}
          navText={["<", ">"]}
        >
          <div
            className="text-left item bg-img"
            data-overlay-dark={4}
            style={{ backgroundImage: `url("assets/images/slider/1.jpg")` }}
            onContextMenu={(e) => e.preventDefault()}
          >
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <h4>Welcome to</h4>
                    <h1>Shiv Design</h1>
                    <h2>3D Architecture & Interior</h2>
                    <p>
                      Managed By: <b>Deep Andharia</b>
                    </p>
                    <Link to="/portfolio" className="button-light">
                      View Project
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-left item bg-img"
            data-overlay-dark={3}
            style={{ backgroundImage: `url("assets/images/slider/2.jpg")` }}
            onContextMenu={(e) => e.preventDefault()}
          >
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <h4>Welcome to</h4>
                    <h1>Shiv Design</h1>
                    <h2>3D Architecture & Interior</h2>
                    <p>
                      Managed By: <b>Deep Andharia</b>
                    </p>
                    <Link to="/portfolio" className="button-light">
                      View Project
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-left item bg-img"
            data-overlay-dark={4}
            style={{ backgroundImage: `url("assets/images/slider/3.jpg")` }}
            onContextMenu={(e) => e.preventDefault()}
          >
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <h4>Welcome to</h4>
                    <h1>Shiv Design</h1>
                    <h2>3D Architecture & Interior</h2>
                    <p>
                      Managed By: <b>Deep Andharia</b>
                    </p>
                    <Link to="/portfolio" className="button-light">
                      View Project
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-left item bg-img"
            data-overlay-dark={4}
            style={{ backgroundImage: `url("assets/images/slider/4.jpg")` }}
            onContextMenu={(e) => e.preventDefault()}
          >
            <div className="v-middle caption">
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <h4>Welcome to</h4>
                    <h1>Shiv Design</h1>
                    <h2>3D Architecture & Interior</h2>
                    <p>
                      Managed By: <b>Deep Andharia</b>
                    </p>
                    <Link to="/portfolio" className="button-light">
                      View Project
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </OwlCarousel>
        <div className="slide-num" id="snh-1" />
        <div className="slider__progress">
          <span />
        </div>
      </header>
    </>
  );
}
