import React, { useEffect, useState } from "react";
import axios from "axios";

import { Link, useNavigate } from "react-router-dom";

export default function PortfolioList() {
  const [portfoliolistdata, setportfoliolistdata] = useState([]);

  const location = useNavigate();

  function ViewPortfolio() {
    axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
        method: "portfolio",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        
        console.log("View Portfolio Data", res.data.data);
        setportfoliolistdata(res.data.data);
      }).catch((e) => {
        console.log('e', e);

      });
  }

  useEffect(() => {
    ViewPortfolio();
  }, []);

  return (
    <>
      <section className="blog-home section-padding" onContextMenu={(e) => e.preventDefault()}>
        <div className="container">
          <div className="row">
       
            {portfoliolistdata?.map((portfoilo) => (
              <div className="col-md-4">
                <div className="item">
                  <div className="post-img">
                    <button
                      class="btncls"
                      type="button"
                      onClick={() =>
                        location("/portfolio-description", {
                          state: { id: portfoilo._id },
                        })
                      }
                    >
                      <div className="img">
                        {" "}
                        <img
                          src={
                            `${process.env.REACT_APP_BACKEND_URL}` +
                            "uploads/" +
                            `${portfoilo.image}`
                          }
                          alt={portfoilo.portfolio_name}
                          onContextMenu={(e) => e.preventDefault()}
                        />{" "}
                      </div>
                    </button>
                  </div>
                  <div className="cont">
                    <h4>
                      <button
                        class="btncls"
                        type="button"
                        onClick={() =>
                          location("/portfolio-description", {
                            state: { id: portfoilo._id },
                          })
                        }
                      >
                        {portfoilo.portfolio_name}
                      </button>
                    </h4>
                    <div className="info">
                      {" "}
                      <Link to="#">
                        <span>Project P.{portfoilo.id}</span>
                      </Link>{" "}
                      <Link to="#">{portfoilo.date}</Link>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
