import React, { useEffect } from "react";
import axios from "axios";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";

import Banner from "./Banner";
import InquiryForm from "./InquiryForm";

import { Link } from "react-router-dom";

export default function Products() {
  useEffect(() => {
    document.title = "Inquiry | Shiv Design 3D Architecture & Interior";
  }, []);

  return (
    <>
      <div>
        <div className="page-wrapper">
          <Header />

          <Banner />

          <InquiryForm />

          <Footer />
        </div>
      </div>
    </>
  );
}
