import "./App.css";
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./Components/Home/Home";
import AboutUs from "./Components/AboutUs/AboutUs";
import Portfolio from "./Components/Portfolio/Portfolio";
import Gallery from "./Components/Gallery/Gallery";
import Inquiry from "./Components/Inquiry/Inquiry";
import ContactUs from "./Components/ContactUs/ContactUs";
import PortfolioDescription from "./Components/Portfolio/PortfolioDescription";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
 
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about-us" element={<AboutUs />} />
        <Route exact path="/contact-us" element={<ContactUs />} /> 
        <Route exact path="/inquiry" element={<Inquiry />} />  
        <Route exact path="/gallery" element={<Gallery />} />       
        <Route exact path="/portfolio" element={<Portfolio />} /> 
        <Route exact path="/portfolio-description" element={<PortfolioDescription />} />        
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
