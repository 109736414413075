import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

export default function VideoGallery() {
  const [videogallerylistdata, setvideogallerylistdata] = useState([]);

  function ViewVideoGallery() {
    axios
      .post(`${process.env.REACT_APP_BACKEND_LINK}`, {
        method: "videogallery",
        submethod: "get",
        key: process.env.REACT_APP_KEY,
      })
      .then((res) => {
        console.log("View Video Gallery Data", res.data.data);
        setvideogallerylistdata(res.data.data);
      });
  }

  useEffect(() => {
    ViewVideoGallery();
  }, []);

  return (
    <>
      <section className="section-padding" onContextMenu={(e) => e.preventDefault()}>
        <div className="container">
          <div className="row mb-5">
            <div className="col-md-4">
              <div className="sub-title border-bot-light">Videos</div>
            </div>
            <div className="col-md-8">
              <div className="section-title">
                <span>Video</span> Gallery
              </div>
            </div>
          </div>
          <div className="row">
            {videogallerylistdata?.map((videogallery) => (
              <div className="col-md-6">
                <div className="vid-area mb-30">
                  <div className="vid-icon">
                    <iframe
                      width="560"
                      height="315"
                      src={videogallery.youtube_link}
                      title="YouTube video player"
                      frameborder="0"
                      width={600}
                      height={400}
                      allowfullscreen
                    ></iframe>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
