import React from "react";

import { Link } from "react-router-dom";

export default function About() {
  return (
    <>
      <section className="about section-padding" onContextMenu={(e) => e.preventDefault()}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-30">
              <div className="sub-title border-bot-light">About</div>
            </div>
            <div className="col-md-8">
              <div className="section-title">
                <span>Who We</span> Are
              </div>
              <p className="palign">
                <b>Shiv Design</b> as a professional 3d architectural rendering
                firm, our first and foremost role is to convert customer's
                imaginations and ideas into virtual reality. Our innovative and
                creative solutions enable us to deliver real shape and
                perspective in 3d support of Various Architectural Firms, Civil
                Engineers, Interior Designers, Builders & Real Estate Agencies.
              </p>
              <p class="palign">
                Our unique design, ideation, and marketing acumen has helped
                carved a niche in the creative world of multimedia. Our vast
                experience and participatory approach empower us to satiate
                every bit of the requirement of the customer though detailed
                study of the design. Our production work banking on the
                experience of our professional team of 3d artists and graphic
                designers. They are pretty much adaptable to the latest designs
                and a taste for design, which particularly satisfy the client's
                requirements.
              </p>
              <br />
              <div className="row">
                <div className="col col-md-4">
                  <div className="about-box">
                    <img
                      src="assets/images/icon-1.png"
                      className="icon"
                      alt=""
                    />
                    <h5>Architecture</h5>
                  </div>
                </div>
                <div className="col col-md-4">
                  <div className="about-box">
                    <img
                      src="assets/images/icon-2.png"
                      className="icon"
                      alt=""
                    />
                    <h5>Interior</h5>
                  </div>
                </div>
                <div className="col col-md-4">
                  <div className="about-box">
                    <img
                      src="assets/images/icon-3.png"
                      className="icon"
                      alt=""
                    />
                    <h5>Planing</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br/><br/><br/><br/>
          <div className="row">
            <div className="col-md-8">
              <div className="section-title">
                <span>Who We</span> Do
              </div>
              <p className="palign">
                Shiv Design produces high quality 3d visualizations (3D exterior
                and interior renderings, architectural walk-through and fly
                through animations, 3d modelling and much more). By having Over
                15 years of experience in this field, we provide high-quality
                designs and visualizing the thoughts of our customers. Our
                innovative technologies empowered us to complete your 3d
                architectural animation projects in a definite time-frame. Our
                photo-realistic rendering expertise gives us a sense of real
                picture of the design, or how things look after the whole
                implementation of ideas and elements.
              </p>
            </div>
            <div className="col-md-4 mb-30">
              <div className="sub-title border-bot-light">20 years of experience.</div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
