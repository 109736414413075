import React from "react";

import { Link } from "react-router-dom";

export default function About() {
  return (
    <>
      <section className="about section-padding" onContextMenu={(e) => e.preventDefault()}>
        <div className="container">
          <div className="row">
            <div className="col-md-4 mb-30">
              {/* <div className="sub-title border-bot-light">About</div> */}
              <div className="section-title">
                Who We <span>Are</span>
              </div>
              <p class="palign">
                <b>Shiv Design</b> as a professional 3d architectural rendering
                firm, our first and foremost role is to convert customer's
                imaginations and ideas into virtual reality. Our innovative and
                creative solutions enable us to deliver real shape and
                perspective in 3d support of Various Architectural Firms, Civil
                Engineers, Interior Designers, Builders & Real Estate Agencies.
              </p>
              <p class="palign">
                Our unique design, ideation, and marketing acumen has helped
                carved a niche in the creative world of multimedia. Our vast
                experience and participatory approach empower us to satiate
                every bit of the requirement of the customer though detailed
                study of the design. Our production work banking on the
                experience of our professional team of 3d artists and graphic
                designers. They are pretty much adaptable to the latest designs
                and a taste for design, which particularly satisfy the client's
                requirements.
              </p>
              <div className="states">
                <ul className="flex">
                  <li className="flex">
                    <div className="numb valign">
                      <h1>20</h1>
                    </div>
                    <div className="text valign">
                      <p>
                        Years
                        <br /> Of Experience
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-4">
              <div className="section-title">
                Who We <span>Do</span>
              </div>
              <p class="palign">
                <b>Shiv Design</b> produces high quality 3d visualizations (3D
                exterior and interior renderings, architectural walk-through and
                fly through animations, 3d modelling and much more). By having
                Over 15 years of experience in this field, we provide
                high-quality designs and visualizing the thoughts of our
                customers. Our innovative technologies empowered us to complete
                your 3d architectural animation projects in a definite
                time-frame. Our photo-realistic rendering expertise gives us a
                sense of real picture of the design, or how things look after
                the whole implementation of ideas and elements.
              </p>
            </div>
            <div className="col-md-4">
              <div className="wrap">
                <div className="con">
                  {" "}
                  <img
                    src="assets/images/about.jpg"
                    className="img-fluid"
                    alt="About"
                    onContextMenu={(e) => e.preventDefault()}
                  />
                  <div className="info">
                    <h4 className="name">Shiv Design</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
       {/*   <div className="row">
            <div className="col-md-8 offset-md-4 mt-5">
              <div className="row">
                <div className="col-md-12 skills">
                  <div className="skill-item">
                    <h6>
                      Architectural Design <i>(90%)</i>
                    </h6>
                    <div className="skill-progress">
                      <div className="progres" data-value="90%" />
                    </div>
                  </div>
                  <div className="skill-item">
                    <h6>
                      3D Modelling <i>(80%)</i>
                    </h6>
                    <div className="skill-progress">
                      <div className="progres" data-value="80%" />
                    </div>
                  </div>
                  <div className="skill-item">
                    <h6>
                      Motion Graphics <i>(90%)</i>
                    </h6>
                    <div className="skill-progress">
                      <div className="progres" data-value="90%" />
                    </div>
                  </div>
                  <div className="skill-item">
                    <h6>
                      Interior Design <i>(70%)</i>
                    </h6>
                    <div className="skill-progress">
                      <div className="progres" data-value="70%" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
                </div> */}
        </div>
      </section>
    </>
  );
}
