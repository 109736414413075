import React from "react";
import axios from "axios";
import { Link, NavLink } from "react-router-dom";

export default function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      {/* Footer */}
      <footer className="footer" onContextMenu={(e) => e.preventDefault()}>
        <div className="top">
          <div className="container">
            <div className="row">
              <div className="col-md-4 mb-30">
                <h3>About</h3>

                <p className="palign">
                  <b>Shiv Design</b> as a professional 3d architectural
                  rendering firm, our first and foremost role is to convert
                  customer's imaginations and ideas into virtual reality. Our
                  innovative and creative solutions enable us to deliver real
                  shape and perspective in 3d support of Various Architectural
                  Firms, Civil Engineers, Interior Designers, Builders & Real
                  Estate Agencies.
                </p>
              </div>
              <div className="col-md-5">
                <div className="item">
                  <h3>
                    Contact <span>Details</span>
                  </h3>
                  <p>
                    <i className="ti-map" /> <b>Shiv Design</b>
                    <br />
                    3D Architecture & Interior
                    <br />
                    <b>Deep Andharia</b>
                    <br />
                    B-8, Sai-shila Complex,
                    <br />
                    Nr D.S.P Office, <br />
                    Bhavnagar - 364001
                    <br />
                    Gujarat - (India)
                  </p>
                  <p className="phone">
                    <a className="clw" href="callto:9712654564">
                      <i className="ti-mobile" /> +91 9712654564
                    </a>
                    <br />
                    <a className="clw" href="callto:8320452771">
                      <i className="ti-mobile" /> +91 8320452771
                    </a>
                  </p>
                  <p className="mail">
                    <a className="clw" href="mailto:dpandharia@gmail.com">
                      <i className="ti-envelope" /> dpandharia@gmail.com
                    </a>
                  </p>
                  <div className="social mt-2">
                    <a href="index.html">
                      <i className="ti-twitter" />
                    </a>
                    <a href="index.html">
                      <i className="ti-instagram" />
                    </a>
                    <a href="index.html">
                      <i className="ti-linkedin" />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-md-2 mb-30">
                <h3>
                  Userful <span>Links</span>
                </h3>
                <div className="sub-title border-footer-light">
                  <Link to="/">Home</Link>
                </div>
                <div className="sub-title border-footer-light">
                  <Link to="/about-us">About Us</Link>
                </div>
                <div className="sub-title border-footer-light">
                  <Link to="/portfolio">Portfolio</Link>
                </div>
                <div className="sub-title border-footer-light">
                  <Link to="/gallery">Gallery</Link>
                </div>
                <div className="sub-title border-footer-light">
                  <Link to="/inquiry">Inquiry</Link>
                </div>
                <div className="sub-title border-footer-light">
                  <Link to="/contact-us">Contact Us</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom">
          <div className="container">
            <div className="row">
              <div className="col-md-8 offset-md-2">
                <p className="fn18">
                  © {currentYear} <b>Shiv Design</b> 3D Architecture & Interior{" "}
                  All Rights Reserved.
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
