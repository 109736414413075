import React, {useEffect} from "react";

import axios from "axios";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";

import { Link } from "react-router-dom";
import ImageGallery from "./ImageGallery";
import VideoGallery from "./VideoGallery";
import Banner from "./Banner";

export default function Gallery() {
  
  useEffect(() => {
    document.title = 'Gallery | Shiv Design 3D Architecture & Interior';
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <Header />

        <Banner />

        <div>
          <ImageGallery />

          <VideoGallery />
        </div>

        <Footer />
      </div>
    </>
  );
}
