import React, {useEffect} from "react";
import axios from "axios";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";

import { Link } from "react-router-dom";
import Banner from "./Banner";
import About from "./About";
import Subscribe from "./Subscribe";

export default function AboutUs() {
  
  useEffect(() => {
    document.title = 'About Us | Shiv Design 3D Architecture & Interior';
  }, []);

  return (
    <>
      <div className="content-wrapper">
      
        <Header />

        <Banner />

        <About />

        <Subscribe />

        <Footer />

      </div>
    </>
  );
}
