import React from "react";
import { Link } from "react-router-dom";

export default function GoogleMap() {
  return (
    <>
      {/* Maps */}
      <div className="google-maps">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3705.3030831440374!2d72.1497406311097!3d21.768527917307832!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395f5bd7b528f925%3A0xe65cf34f8f5afb92!2sShiv%20Design%203D%20Architecture%20%26%20Interior!5e0!3m2!1sen!2sin!4v1692949714279!5m2!1sen!2sin"
          width={600}
          height={450}
          style={{ border: 0 }}
          allowFullScreen
          referrerpolicy="no-referrer-when-downgrade"
        />
      </div>
    </>
  );
}
